<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <validation-provider
          #default="validationContext"
          name="Full Name"
          rules="required"
      >
        <b-form-group :label="$t('message.Name')" for="name">
          <b-form-input autofocus v-model="tours.tour.name"></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <label class="mt-2">{{$t('message.Avia Tickets')}}</label>
      <b-form-select
          v-model="tours.tour.avia_ticket_id"
          :options="avia_tickets"
          text-field="name"
          value-field="id"
          @input="getTicketSelect"
          style="height: 40px"
          class="w-100"
      />
      <b-row>
        <b-col cols="6">
          <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
          >
            <div class="w-100 mt-1">
              <label for="start_date">{{$t('message.StartDate')}}</label>
              <input type="date" v-model="tours.tour.start_date" class="form-control">
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
          >
            <div class="w-100 mt-1">
              <label for="end_date">{{$t('message.EndDate')}}</label>
              <input type="date" v-model="tours.tour.end_date" class="form-control">
            </div>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="6">
          <div class="w-100">
            <label class="mt-2">{{$t('message.Partners')}}</label>
            <b-form-select
                v-model="tours.tour.partner_id"
                :options="partners"
                text-field="name"
                value-field="id"
                class="w-100"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100">
            <label class="mt-2">{{$t('message.Type')}}</label>
            <b-form-select
                v-model="tours.tour.type"
                :options="types"
                text-field="name"
                value-field="value"
                class="w-100"
            />
          </div>
        </b-col>
      </b-row>
      <div class="w-100 mt-2">
        Add Hotels
        <b-row>
          <b-col cols="10">
            <div class="w-100">
              <v-select
                  v-model="selectedHotels"
                  multiple
                  :options="tours.hotels"
                  label="name"
                  @search="searchHotel"
                  class="w-100"
                  @option:deselecting="deselectHotel">
              </v-select>
            </div>
            <div class="w-100 mt-3">
              <b-list-group>
                <b-list-group-item v-for="(hotel, index) in selectedHotels" :key="index">
                 <div class="w-100">
                   <b-row>
                     <b-col cols="10">
                       {{hotel.name}}
                     </b-col>
                     <b-col cols="1">
                       <b-button variant="danger" size="sm" @click="removeHotel(index)">
                         <b-icon icon="trash"></b-icon>
                       </b-button>
                     </b-col>
                   </b-row>
                 </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" size="sm" @click="addNewHotelModal=true">
              <b-icon icon="plus"></b-icon>Add</b-button>
          </b-col>
        </b-row>
      </div>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
    <b-modal ok-only size="lg" v-model="addNewHotelModal" hide-footer no-close-on-backdrop>
      <HotelsForm
          :id="id"
          :countries="countries"
          @closeModal="closeHotelModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import {addTours, editTours, getToursById} from "@/api/tours";
import {createTicketsById} from "@/api/avia-tickets";
import { getPartners } from '@/api/partners'
import { getHotels } from '@/api/hotels'
import vSelect from 'vue-select'
import HotelsForm from '@/views/directories/hotels/components/HotelsForm'
import { getCountries } from '@/api/countries/countries'


export default {
  name: 'ToursModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    HotelsForm,
  },
  props:{
    new_ticket:{
      default: null
    },
    id:{
      default:null
    }
  },
  data() {
    return {
      required,
      alphaNum,
      tours: {
        tour:{
          id: null,
          name: '',
          avia_ticket_id:'',
          start_date: null,
          end_date: null,
          partner_id: null,
          type: 'public',
        },
        hotels: [],
      },
      avia_tickets:[],
      formModalActive: false,
      partners: [],
      types: [
        {name: "Public",value: 'public'},
        {name: "Private",value: 'private'},
      ],
      selectedHotels: [],
      clientHotelChoice: null,
      addNewHotelModal: false,
      countries: [],
    }
  },
  mounted() {
    createTicketsById().then(res => {
      this.avia_tickets = res.data.data
      this.avia_tickets.unshift({name:'add_ticket', id:0})
    });
    this.fetchPartners()
    this.fetchCountries()
    },
  methods: {
    fetchTours() {
      getToursById(this.id).then(res => {
        this.tours.tour = res.data.data;
        this.selectedHotels = this.tours.tour.hotels;
      })
    },
    getTicketSelect(){
      if(this.tours.avia_ticket_id === 0){
        this.$emit("openTicketModal")
      }
    },
    fetchPartners() {
      getPartners().then(res => {
        this.partners = res.data.data.data;
      })
    },
    closeModal(){
      this.formModalActive = false
    },
    removeHotel(index) {
      this.selectedHotels.splice(index,1)
    },
    searchHotel(search) {
      this.searchTimeOut = setTimeout(() => {
        getHotels({search}).then(res => {
          this.tours.hotels = res.data.data;
        })
      },400)
    },
    deselectHotel(data) {
      if(this.clientHotelChoice) {
        if(data.id === this.clientHotelChoice) {
          this.clientHotelChoice = null;
        }
      }
    },
    passHotels() {
      this.$emit('passHotels', this.selectedHotels,this.selectedHotels.filter(item => item.id === this.clientHotelChoice)[0])
    },
    fetchCountries() {
      getCountries().then(res => {
        this.countries = res.data.data.data;
      })
    },
    closeHotelModal(data=null) {
      this.addNewHotelModal = false;
      if(data) {
        this.tours.hotels.push(data);
        this.selectedHotels.push(data);
      }
    },
    handleSubmit() {
      const isValid = this.$refs.refFormObserver.validate()
      if (isValid) {
        if (this.id) {
          this.tours.hotels = this.selectedHotels.map(item => item.id)
          this.tours.id = this.id;
          // eslint-disable-next-line no-unused-vars
          editTours(this.tours).then(res => {
            this.$emit('closeModal')
          })
        } else {
          this.tours.hotels = this.selectedHotels.map(item => item.id)
          // eslint-disable-next-line no-unused-vars
          addTours(this.tours).then(res => {
            this.$emit('closeModal')
          })
        }
      }
    },
  },
  watch: {
    id:{
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchTours()
        }
      }
    },
    new_ticket:{
      immediate: true,
      handler(val){
        if(val){
          this.
          this.avia_tickets.push(val)
          this.tours.avia_ticket_id = val.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
